import { __rest as r, __assign as o } from "../../_virtual/_tslib.js";
import { jsx as i, jsxs as n } from "react/jsx-runtime";
import * as t from "react";
import { Grid as c } from "@mui/material";
import { Icon as e } from "../icon/icon.component.js";
import { Typography as a } from "../typography/typography.component.js";
import { StyledCardButton as m, StyledIconWrapper as p } from "./card-button.styles.js";
var l = t.forwardRef(function (t, l) {
  var s = t.icon,
      d = t.iconBg,
      f = t.iconBgCustom,
      g = t.iconColor,
      h = t.children,
      u = r(t, ["icon", "iconBg", "iconBgCustom", "iconColor", "children"]);
  return i(m, o({
    ref: l,
    size: "extraLarge"
  }, u, {
    children: n(c, o({
      alignItems: "center",
      container: !0,
      spacing: 1.5,
      wrap: "nowrap"
    }, {
      children: [s && i(c, o({
        item: !0
      }, {
        children: i(p, o({
          $iconBg: d,
          $iconBgCustom: f
        }, {
          children: i(e, {
            color: g,
            src: s
          })
        }))
      })), i(c, o({
        item: !0
      }, {
        children: "string" == typeof h ? i(a, o({
          color: "nightWizard",
          fontWeight: 500,
          variant: "body2"
        }, {
          children: h
        })) : h
      }))]
    }))
  }));
});
l.displayName = "CardButton";

var s = function (r) {
  return i(l, o({}, r));
};

export { s as CardButton };