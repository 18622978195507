import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Button, pxToRem } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    '& .rpv-core__inner-page': {
      background: theme.palette.ghostWhite.main,
      display: 'flex',
      justifyContent: 'center'
    },
    '& .rpv-core__page-layer::after': {
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
      left: 0,
      right: 0,
      top: 0
    },
    '& .rpv-core__text-layer': {
      display: 'none'
    },
    '& .rpv-core__text-layer-text': {
      display: 'none'
    }
  };
  return styles;
});
var StyledWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var height = theme.convertToDVH(100);
  var styles = {
    '& > div': {
      height: '100%'
    },
    height: "calc(".concat(height, " - ").concat(pxToRem(176), ")"),
    marginTop: theme.spacing(2),
    minHeight: pxToRem(300)
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    '&:hover': {
      boxShadow: theme.boxShadows.independence,
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(12),
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  };
  return styles;
});
var StyledStack = styled(Stack)(function () {
  var styles = {
    '& > div': {
      width: '100%'
    }
  };
  return styles;
});
var StyledButton = styled(Button)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    height: 'unset',
    padding: theme.spacing(2),
    width: '100%'
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  };
  return styles;
});
var StyledSkeletonWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexGrow: '1',
    marginTop: theme.spacing(-7)
  }, theme.breakpoints.up('desktop'), {
    marginTop: theme.spacing(-21)
  });

  return styles;
});
export { StyledBox, StyledButton, StyledButtonWrapper, StyledContainer, StyledSkeletonWrapper, StyledStack, StyledWrapper };