import * as yup from 'yup';
import { MAX_NAME_FIELD_LENGTH } from '@enums';
import { i18n } from '@localization';

var getCardIssueFirstStepSchema = function getCardIssueFirstStepSchema() {
  return yup.object();
};

var getCardIssueSecondStepSchema = function getCardIssueSecondStepSchema() {
  return yup.object().shape({
    cardholderName: yup.string().required(i18n.t('forms.validation.required')),
    cardholderSurname: yup.string().required(i18n.t('forms.validation.required')).test({
      message: i18n.t('pages.cardIssue.nameValidation', {
        count: MAX_NAME_FIELD_LENGTH
      }),
      name: 'cardholderSurname',
      test: function test(value, ctx) {
        var _fields$cardholderNam;

        if (!value) {
          return true;
        }

        var fields = ctx.parent;

        if (value.length + ((_fields$cardholderNam = fields.cardholderName) === null || _fields$cardholderNam === void 0 ? void 0 : _fields$cardholderNam.length) > MAX_NAME_FIELD_LENGTH) {
          return false;
        }

        return true;
      }
    }),
    checkAgreement: yup.bool().equals([true]),
    checkContract: yup.bool().equals([true]),
    checkPersonalData: yup.bool().when('isWithAccountOpen', {
      is: true,
      then: yup.bool().equals([true])
    }),
    codeWord: yup.string().trim().min(4, i18n.t('forms.validation.minLengthCode')).required(i18n.t('forms.validation.required'))
  });
};

var getCardIssueThirdStepSchema = function getCardIssueThirdStepSchema() {
  return yup.object().shape({
    checkSMSAgreement: yup.bool().when('isAddSMS', {
      is: true,
      then: yup.bool().equals([true])
    })
  });
};

export { getCardIssueFirstStepSchema, getCardIssueSecondStepSchema, getCardIssueThirdStepSchema };