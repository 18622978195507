var getInitialTitleAndCard = function getInitialTitleAndCard(cards, productCode) {
  var pageTitle = '';
  var initialCard = cards[0];

  if (productCode) {
    var currentCard = cards.find(function (_ref) {
      var productCode = _ref.productCode;
      return productCode === productCode;
    });
    pageTitle = (currentCard === null || currentCard === void 0 ? void 0 : currentCard.cardType) || '';
    initialCard = currentCard || cards[0];
  }

  return {
    initialCard: initialCard,
    pageTitle: pageTitle
  };
};

export { getInitialTitleAndCard };