import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { alpha, Box, Stack, styled } from '@mui/material';
var StyledContent = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1'
  }, theme.breakpoints.up('desktop'), {
    flexGrow: 'unset'
  });

  return styles;
});
var StyledShadow = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;
  var styles = {
    backgroundColor: alpha(theme.colors.black, 0.2),
    borderRadius: 16,
    bottom: '-3%',
    filter: "blur(".concat(theme.typography.pxToRem(12.5), ")"),
    height: '6%',
    left: '-10%',
    pointerEvents: 'none',
    position: 'absolute',
    right: '-10%'
  };
  return styles;
});
var StyledImageWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    '& img': {
      borderRadius: pxToRem(10),
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    margin: '0 auto',
    marginTop: theme.spacing(3),
    maxWidth: pxToRem(219),
    position: 'relative'
  }, theme.breakpoints.up(456), {
    maxWidth: pxToRem(300)
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: 12,
    padding: theme.spacing(2)
  };
  return styles;
});
var StyledButtonWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    marginTop: theme.spacing(2.5)
  };
  return styles;
});
var StyledCheckboxWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    paddingLeft: theme.spacing(1)
  };
  return styles;
});
var StyledStack = styled(Stack)(function (_ref7) {
  var theme = _ref7.theme,
      $isMobil = _ref7.$isMobil;
  var styles = $isMobil ? {
    backgroundColor: theme.palette.white.main,
    borderBottomLeftRadius: pxToRem(16),
    borderBottomRightRadius: pxToRem(16),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingBottom: theme.spacing(3)
  } : {};
  return styles;
});
export { StyledBox, StyledButtonWrapper, StyledCheckboxWrapper, StyledContent, StyledImageWrapper, StyledShadow, StyledStack };